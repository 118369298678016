import React, { useState } from 'react';
import { IconButton, Box, Avatar } from '@mui/material'; // Material-UI components
import { motion } from 'framer-motion'; // Framer Motion for animation
// import { FaShip } from 'react-icons/fa'; // Importing a boat icon from react-icons
import AmiChat from './AmiChat';
import AmiLogo from '../../assets/images/Asset 9@8x-neophyte-black.png';

const FloatingChatbot = () => {
  const [isOpen, setIsOpen] = useState(false); // State to control popup visibility

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderRadius: '10px'
      }}
    >
      {/* Chat Popup */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            // maxWidth: '500px',
            width: '1000px',
            padding: '0px',
            marginBottom: '10px'
          }}
        >
          {/* <iframe src="http://216.48.183.210:8505/" width="100%" height="600px" frameBorder="0" title="Streamlit UI" className="rounded-lg" /> */}
          {/* <iframe src="https://deft-kangaroo-acd620.netlify.app/" width="100%" height="600px" frameBorder="0" title="Streamlit UI" className="rounded-lg" /> */}
          <AmiChat />
        </motion.div>
      )}

      {/* Floating Icon Button with Text */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={toggleChat}
          style={{
            backgroundColor: '#ca8a04',
            color: '#fff'
          }}
        >
          {/* <FaShip size={24} /> Boat Icon */}
          <Avatar
            src={AmiLogo}
            alt="Ami Logo"
            sx={{
              width: 40,
              height: 40,
              // marginRight: '0.2rem',
              backgroundColor: 'black',
              border: '3px solid #ca8a04',
              // border:'2px solid red',
              // padding: '4px',
              flexShrink: 0,
              '& img': {
                width: '20px',
                height: '20px'
              }
            }}
          />
        </IconButton>

        {/* Text: How can I help you? */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            marginLeft: '10px',
            color: '#ca8a04',
            fontSize: '1rem',
            fontWeight: '500',
            whiteSpace: 'nowrap'
          }}
        >
          How can I help you?
        </motion.div>
      </Box>
    </Box>
  );
};

export default FloatingChatbot;
