import React, { useState, useRef, useEffect } from 'react';
import { Paper } from '@mui/material';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';

const AmiChat = () => {
  const [messages, setMessages] = useState([
    {
      text1:
        "Hello, I'm Ami.\nIts nice to talk to you!\nSome initial info - I'm an AI assistant created to showcase our company's LLM capabilities.\nFeel free to ask me anything!",
      text2:
        "Hello, I'm Ami.\nIts nice to talk to you!\nSome initial info - I'm an AI assistant created to showcase our company's LLM capabilities.\nFeel free to ask me anything!",
      isUser: false
    }
  ]);

  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() && !isLoading) {
      const userMessage = input.trim();
      setInput('');
      setMessages((prev) => [...prev, { text2: userMessage, isUser: true }]);
      setIsLoading(true);

      // Helper function to handle API calls
      const fetchAPI = async (url, body) => {
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return await response.json();
        } catch (error) {
          console.error(`Error fetching data from ${url}:`, error.message);
          return null;
        }
      };

      // Handle the first API call
      const handleFirstAPI = async () => {
        try {
          const result1 = await fetchAPI('https://6mkygxqqz3.execute-api.ap-south-1.amazonaws.com/store_analysis/ami_fashion/v1', {
            prompt: userMessage
          });
          setMessages((prev) => [
            ...prev,
            {
              text1: result1?.data?.res || 'No response from API 1.',
              isUser: false
            }
          ]);
        } catch (error) {
          console.error('Error fetching API 1:', error.message);
          setMessages((prev) => [...prev, { text1: 'I apologize, API 1 is not responding.', isUser: false }]);
        }
      };

      // Handle the second API call
      const handleSecondAPI = async () => {
        try {
          const result2 = await fetchAPI('https://neoami.ddns.net/', { prompt: userMessage });
          setMessages((prev) => [
            ...prev,
            {
              text2: result2?.raw || 'No response from API 2.',
              isUser: false
            }
          ]);
          console.log('result2', result2);
        } catch (error) {
          console.error('Error fetching API 2:', error.message);
          setMessages((prev) => [...prev, { text2: 'I apologize, API 2 is not responding.', isUser: false }]);
        } finally {
          setIsLoading(false);
        }
      };

      // Call both APIs independently
      handleFirstAPI();
      handleSecondAPI();

      // This ensures the loading state resets after initiating both calls
    }
  };

  console.log('response messages are ', messages);
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = (e) => {
    e.stopPropagation();
  };

  return (
    <div className=" max-w-6xl mx-auto bg-black flex items-center justify-center scrollbar rounded-lg p-4">
      <Paper
        elevation={3}
        className="w-full bg-gray-900 rounded-3xl overflow-hidden shadow-xl flex flex-col h-[calc(100vh-2rem)] max-h-[80vh]"
      >
        <div className="flex-grow overflow-y-auto p-6  scrollbar grid grid-cols-2 gap-4" ref={messagesContainerRef} onScroll={handleScroll}>
          {messages.map((message, index) => (
            <ChatMessage key={index} indexing={index} text={message} isUser={message.isUser} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput input={input} isLoading={isLoading} onInputChange={setInput} onSubmit={handleSubmit} />
      </Paper>
    </div>
  );
};

export default AmiChat;
