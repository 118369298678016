import React from 'react';
import { Paper, Typography } from '@mui/material';

const ChatMessage = ({ text, isUser, indexing }) => {
  const isFullWidth = isUser || indexing === 0; // Determines if the message takes the full width

  return (
    <div className={`grid ${isFullWidth ? 'col-span-2' : 'col-span-1'}  items-start mb-4`}>
      {/* Render text1 for bot messages */}
      {/* {!isUser && text.text1 && (
        <Paper
          elevation={1}
          className="max-w-full rounded-2xl p-4 relative border border-gray-600 bg-black text-white"
          sx={{
            '&.MuiPaper-root': { backgroundImage: 'none' }
          }}
        >
          <Typography
            variant="h6"
            component="div"
            className="font-bold mb-2 text-lg text-white"
            sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '1.125rem' }}
          >
            Ami
          </Typography>
          <Typography
            component="p"
            className="whitespace-pre-wrap text-base"
            sx={{ fontSize: '1rem' }}
          >
            {text.text1}
          </Typography>
        </Paper>
      )} */}

      {/* Render text2 for both user and bot messages */}
      <Paper
        elevation={1}
        className={`max-w-full rounded-2xl p-4 relative border border-gray-600 ${
          isUser ? 'bg-[#003748] text-white' : 'bg-black text-white'
        }`}
        sx={{
          backgroundColor: isUser ? '#003748' : 'black',
          color: 'white',
          '&.MuiPaper-root': { backgroundImage: 'none' }
        }}
      >
        {!isUser && (
          <Typography
            variant="h6"
            component="div"
            className="font-bold mb-2 text-lg text-white"
            sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '1.125rem' }}
          >
            {indexing === 0 ? 'Ami' : text?.text2 ? `Ami (response-2)` : `Ami (response-1)`}
          </Typography>
        )}
        <Typography component="p" className="whitespace-pre-wrap text-base" sx={{ fontSize: '1rem' }}>
          {text?.text2 ? text?.text2 : text?.text1}
        </Typography>
      </Paper>
    </div>
  );
};

export default ChatMessage;
